@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Red+Rose:wght@300..700&display=swap');

.App{
    /* font-family: "Montserrat", sans-serif !important; */
    background-color: #000;
}

/* slider */

@keyframes bounceIn {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    50% {
      transform: scale(1.05);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  
  .slick-slide {
    animation: bounceIn 0.5s ease-in-out;
  }

/* OUR BRANDS */

 .custom-bg-img {
  background-image: url('../images/our\ brand\ bg.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat; 
  width: 100%;
  }

/* logo cloud */

.animate-scroll {
  display: flex;
  animation: scroll 50s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150%);
  }
}


/*footer subscribe button*/

.btn-conteiner {
  display: flex;
  --color-text: #ffffff; 
  --color-background: #dc2626;/*#ff135a;*/
  --color-outline: #ff145b80;
  --color-shadow: #00000080;
}

.btn-content {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: var(--color-text);
  background: var(--color-background);
  transition: 1s;
  box-shadow: 0 0 0.2em 0 var(--color-background);
}

.btn-content:hover, .btn-content:focus {
  transition: 0.5s;
  -webkit-animation: btn-content 1s;
  animation: btn-content 1s;
  outline: 0.1em solid transparent;
  outline-offset: 0.2em;
  box-shadow: 0 0 0.4em 0 var(--color-background);
}

.btn-content .icon-arrow {
  transition: 0.5s;
  margin-right: 0px;
  transform: scale(0.6);
}

.btn-content:hover .icon-arrow {
  transition: 0.5s;
  margin-right: 15px;
}

.icon-arrow {
  width: 10px;
  margin-left: 5px;
  position: relative;
  top: 2%;
}
  
/* SVG */
#arrow-icon-one {
  transition: 0.4s;
  transform: translateX(-60%);
}

#arrow-icon-two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.btn-content:hover #arrow-icon-three {
  animation: color_anim 1s infinite 0.2s;
}

.btn-content:hover #arrow-icon-one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.btn-content:hover #arrow-icon-two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */
@keyframes color_anim {
  0% {
    fill: white;
  }

  50% {
    fill: var(--color-background);
  }

  100% {
    fill: white;
  }
}

/* Button animations */
@-webkit-keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}

@keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}


/* contact map */
  
.map-container {
  background-color: #000;
  color: #fff; 
  display: block; 
  position: relative; 
}

.map-container iframe {
  filter: invert(1) brightness(0.8);
}
